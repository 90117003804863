@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1f9751;
  color: #fff;
}
.App {
  /* display: flex; */
}
.App .container{
  flex-grow: 1;
  padding: 0 60px;
}

.add-project-form {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .add-project-form input,
  .add-project-form textarea,
  .add-project-form select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .add-project-form button {
    padding: 0.5rem 1rem;
    background-color: #777;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .add-project-form button:hover {
    background-color: #777;
  }
  
.table {
   background-color: #fff;
   width: 100%;
   max-width: 100%;
   max-height: 100%;
   display: flex;
   flex-direction: column;
   box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
}

.table-title {
  color: #fff;
  background-color: #2f2f2f;
  padding: 15px;
}

.button-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.button-container button {
  font-size: inherit;
  padding: 10px 15px;
  border:0;
  margin: 0;
  outline: 0;
  border-radius: 0;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
td {
  display: table-cell;
  vertical-align: inherit;
}

table, th, td, tr {
    border: solid 1px #d8d8d8;
    border-left: 0;
    border-right: 0;
    text-align: center;
  }
  
.table {
  display: block;
}
  
  

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    width: 80%;
    max-width: 500px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
.container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 960px;
    margin: 60px auto;
  }
  .content {
    padding-right: 30px;
  }
  .sidebar {
    padding-left: 30px;
  }
  /* .sidebar form {
    padding: 20px;
    background: #1f9751;
    border-radius: 10px; */
  /* } */
  /* .sidebar input, .sidebar select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    color: #555;
    font-size: 1em;
  } */
  /* .sidebar label {
    margin: 0 auto 20px;
    display: block;
    color: #fff;
  } */
  /* .sidebar button {
    color: #fff;
    border: 2px solid #fff;
    padding: 6px 12px;
    background-color: transparent;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
  } */
  /* .sidebar h3 {
    color: #1f9751;
    margin-bottom: 20px;
  }
  .sidebar aside {
    margin-top: 40px;
    color: #555;
  }
  .sidebar aside li {
    margin: 10px;
  } */
  

.register-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px;
}
.register-form label {
  display: block;
  margin: 30px auto;
}
.register-form span {
  display: block;
  margin-bottom: 6px;
}
.register-form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 8px;
}
.login-form {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
  }
  .login-form label {
    display: block;
    margin: 30px auto;
  }
  .login-form span {
    display: block;
    margin-bottom: 6px;
  }
  .login-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  }
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 8px;
  }
.navbar {
  width: 100%;
  background: #effaf0;
  padding: 20px 10px;
  box-sizing: border-box;
}
.navbar ul {
  display: flex;
  margin: 0 auto;
  max-width: 960px;
  align-items: center;
}
.title {
  margin-right: auto;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.2em;
}
.navbar button, .navbar a {
  margin-left: 16px;
}
.navbar a {
  color: #333;
  text-decoration: none;
}
