
.add-project-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .add-project-form input,
  .add-project-form textarea,
  .add-project-form select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .add-project-form button {
    padding: 0.5rem 1rem;
    background-color: #777;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .add-project-form button:hover {
    background-color: #777;
  }
  