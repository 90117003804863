.table {
   background-color: #fff;
   width: 100%;
   max-width: 100%;
   max-height: 100%;
   display: flex;
   flex-direction: column;
   box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
}

.table-title {
  color: #fff;
  background-color: #2f2f2f;
  padding: 15px;
}

.button-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.button-container button {
  font-size: inherit;
  padding: 10px 15px;
  border:0;
  margin: 0;
  outline: 0;
  border-radius: 0;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
td {
  display: table-cell;
  vertical-align: inherit;
}

table, th, td, tr {
    border: solid 1px #d8d8d8;
    border-left: 0;
    border-right: 0;
    text-align: center;
  }
  
.table {
  display: block;
}
  
  
