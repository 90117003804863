.container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 960px;
    margin: 60px auto;
  }
  .content {
    padding-right: 30px;
  }
  .sidebar {
    padding-left: 30px;
  }
  /* .sidebar form {
    padding: 20px;
    background: #1f9751;
    border-radius: 10px; */
  /* } */
  /* .sidebar input, .sidebar select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    color: #555;
    font-size: 1em;
  } */
  /* .sidebar label {
    margin: 0 auto 20px;
    display: block;
    color: #fff;
  } */
  /* .sidebar button {
    color: #fff;
    border: 2px solid #fff;
    padding: 6px 12px;
    background-color: transparent;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
  } */
  /* .sidebar h3 {
    color: #1f9751;
    margin-bottom: 20px;
  }
  .sidebar aside {
    margin-top: 40px;
    color: #555;
  }
  .sidebar aside li {
    margin: 10px;
  } */
  
